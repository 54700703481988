import React, { useState } from "react";
import { Input, Button, Typography } from "antd";

import useDebounce from "./hooks/useDebounce";

import usePost from "./hooks/usePost";

import "./SignUpBox.css";

// how to send messages
// client = Twilio::REST::Client.new
// client.messages.create(from: Rails.application.credentials.dig(:twilio_phone_number), to: '+18777804236', body: 'from console')

const SignUpBox = () => {
  const token = window.document.querySelector(
    'meta[name="csrf-token"]'
  ).content;

  const [val, setVal] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [submitPressed, setSubmitPressed] = useState(false);

  const handleChange = (e) => {
    const v = e.target.value;
    if (v === "" || v.match(/^\d+$/)) {
      setVal(v);
      if ([10, 11].includes(v.length)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const [postMessage, { data, error, isLoading }] = usePost("/api/v1/sign_ups");

  const handleSubmit = async () => {
    await postMessage({ phoneNumber: val }, token).then(() => {});
  };

  const debounceSubmit = useDebounce(handleSubmit, 2000);

  return (
    <div className="signUpFormContainer">
      {!submitPressed && (
        <Input
          className="signUpItem"
          placeholder="phone number"
          value={val}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      )}
      {!disabled && !submitPressed && (
        <Button
          className="signUpItem"
          type="primary"
          onClick={() => {
            setSubmitPressed(true);
            setDisabled(true);
            debounceSubmit();
          }}
        >
          tfti
        </Button>
      )}
      {submitPressed && (
        <Typography.Title
          className="signUpWaitListMessage"
          level={4}
          style={{ margin: 0 }}
        >
          you're on the waitlist
        </Typography.Title>
      )}
    </div>
  );
};

export default SignUpBox;
