import React, { useState } from "react";

import "./App.css";
import SignUpBox from "./SignUpBox";

import { ConfigProvider } from "antd";

// how to send messages
// client = Twilio::REST::Client.new
// client.messages.create(from: Rails.application.credentials.dig(:twilio_phone_number), to: '+18777804236', body: 'from console')

const App = () => {
  // Removed type annotation
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryBorderHover: "#231f20",
              colorPrimaryHover: "#636363",
              colorPrimary: "#231f20",
              colorPrimaryActive: "#636363",
              // colorPrimaryTextHover: "#636363",
              controlItemBgActive: "#636363",
              colorInfoActive: "#636363",
              colorInfoBorder: "#636363",
              colorInfoBorderHover: "#636363",
              colorInfoHover: "#636363",
              colorInfoText: "#636363",
              colorInfoTextActive: "#636363",
              colorInfoTextHover: "#636363",
              colorLinkActive: "#636363",
              colorLinkHover: "#636363",
              colorPrimaryActive: "#636363",
              colorPrimaryBorder: "#636363",
              colorPrimaryBorderHover: "#636363",
              colorPrimaryHover: "#636363",
              colorPrimaryText: "#636363",
              colorPrimaryTextActive: "#636363",
              colorPrimaryTextHover: "#636363",
              colorInfoBg: "#636363",
              colorPrimaryBg: "#636363",
              colorPrimaryBgHover: "#636363",
              colorInfoBgHover: "#636363",
            },
          },
        }}
      >
        <div className="mainAppWrapper">
          <div
            className="mainLogoWrapperClick"
            onClick={() => {
              setClicked(true);
            }}
          >
            <img
              src="/images/tifty_logo.svg"
              className="centeredDiv paddingRightIMage zoomLogo"
              alt="tifty"
            />
          </div>
          {clicked && <SignUpBox />}
        </div>
      </ConfigProvider>
    </>
  );
};

export default App;
